.UpdateShowroom {
    height: 100%;
    width: 81.25%;
    margin: 5.47% 9.38% 6.84% 9.38%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.HeadingText {
    height: 5%;
    width: fit-content;
    align-self: flex-start;
    text-align: left;
    font: normal normal bold 16px/18px Arial;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0 0 6.25% 0;
}

.InputElement {
    height: 15.2%;
    width: 100%;
    margin: 0 0 3.37% 0;
}

.SaveButton {
    height: 6.56%;
    width: 15.39%;
    background-color: #843D96;
}

.TextStyle {
    height: 30.77%;
    width: fit-content;
    margin: 0 0 0.96% 0;
    font: normal normal 14px/16px Arial;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DFF0F7;
    opacity: 1;
    /* Firefox */
}

.InputStyle {
    height: 61.54%;
    width: 100%;
    font: normal normal 14px/16px Arial;
    box-sizing: border-box;
    padding: 1px 7px 1px 7px;
    background-color: #DFF0F7;
    color: #000000;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1px 7px 1px 7px;
    border: 1px solid;
}

.Invalid {
    height: 61.54%;
    width: 100%;
    border: 1px solid red;
    background-color: #fda49a;
    color: #000000;
    font: normal normal 14px/16px Arial;
    box-sizing: border-box;
    margin: 0 0 2.89% 0;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1px 10px 1px 10px;
}

.Disabled {
    height: 50%;
    width: 100%;
    font: normal normal 14px/16px Arial;
    background: #c2c2c2 0% 0% no-repeat padding-box;
    color: #000000;
    padding: 1px 2px 1px 2px;
}

.InputStyleDisabled {
    height: 61.54%;
    width: 100%;
    font: normal normal 14px/16px Arial;
    box-sizing: border-box;
    margin: 0 0 2.89% 0;
    background-color: #DFF0F7;
    color: #000000;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1px 7px 1px 7px;
    border: 1px solid;
    background: #c2c2c2 0% 0% no-repeat padding-box;
    cursor: not-allowed;
}

.InputStyle:disabled {
    background: #c2c2c2 0% 0% no-repeat padding-box;
    cursor: not-allowed;
}

.ToText {
    height: 100%;
    width: fit-content;
    margin: 0 1.44% 0 1.44%;
    display: flex;
    align-items: center;
}

.Button {
    height: 9.36%;
    width: 15.39%;
    border: none;
    cursor: pointer;
    font: inherit;
    padding: 0;
    background: #843D96 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
    font: normal normal bold 18px/21px Arial;
    letter-spacing: 0px;
    text-decoration: none;
    text-align: center;
    align-self: center;
    margin: 2.89% 0 0 0;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #ffffff;
}

.Danger {
    color: #944317;
}