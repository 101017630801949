.ShowroomComponent {
    height: 93.52%;
    width: 31.06%;
    background: #FAFAFA;
    margin: 2.08% 1.14% 2.08% 1.14%;
    display: flex;
    justify-content: left;
    flex-direction: column;
}

.TextHeading {
    height: 2.52%;
    width: fit-content;
    margin: 0 0 1.22% 0;
    font: normal normal 14px/16px Arial;
}

.TextData {
    height: 5.04%;
    width: 100%;
    margin: 0 0 3.66% 0;
    background: #d3d2d2 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: left;
    align-items: center;
}

.Text {
    height: 50%;
    width: fit-content;
    margin: 2.44% 0 2.44% 2.44%;
    font: normal normal 14px/16px Arial;
    display: flex;
    justify-content: left;
    align-items: center;
}

.DateData {
    height: 100%;
    width: 28.05%;
    background: #d3d2d2 0% 0% no-repeat padding-box;
    margin: 0 1.83% 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 14px/16px Arial;
}

.TimeData {
    height: 100%;
    width: 15.85%;
    background: #d3d2d2 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 14px/16px Arial;
}

.TimeZoneData {
    height: 2.52%;
    width: 73.48%;
    margin: 0 0 3.66% 26.52%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.ParticipantsData {
    height: 19.06%;
    width: 100%;
    margin: 0 0 7.32% 0;
    background: #d3d2d2 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: left;
    align-items: center;
}

.ShowroomActions {
    height: 5.04%;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    margin: 0 0 0 0;
}

.CloseButton {
    height: 12px;
    width: 12px;
    margin: 0 2% 0 0;
    cursor: pointer;
}