.AnalyticsTile {
    height: 59.82%;
    width: 26.75%;
    box-shadow: 0px 2px 4px #00000080;
    border: 1px solid #0A2F42;
    margin: 0 2.08% 2.08% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.AnalyticsTileHeader {
    height: 16.29%;
    width: 100%;
    background-color: #092B3C;
    margin: 0 0 0 0;
    font: normal normal bold 18px/21px Arial;
    color: white;
    position: relative;
}

.AnalyticsTileImage {
    height: 83.71%;
    width: 100%;
    background-color: white;
    margin: 0 0 0 0;
}