.AnalyticsMain {
    height: 77%;
    width: 90.05%;
    margin: 2.09% 4.97% 5.59% 4.97%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AnalyticsDashboard {
    height: 100%;
    width: 100%;
}

.AnalyticsComponent {
    height: 45%;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: auto;
    flex-direction: row;
    margin: 1% 0 0 0;
}

.HeadingText {
    width: 100%;
    font: normal normal bold 18px/21px Arial;
    margin: 1% 0 0 0;
}