.MeetingAnalyticsMain {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: auto;
    row-gap: 0%;
}

.Graph{
    height: 50%;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}