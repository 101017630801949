.SetUpShowroomModal {
    height: 52.73%;
    width: 44.68%;
    z-index: 500;
    background-color: white;
    box-shadow: 1px 1px 1px black;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8.46% 27.66% 10.47% 27.66%;
}